@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  /* color: rgb(var(--foreground-rgb)); */
  color: #000;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb)))
    rgb(var(--background-start-rgb));
}

::selection {
  /* bg-pink-700: */
  background-color: #be185d;
  color: #fff;
  /* background-color: #6ee7b7;
  color: #000;
  */
}

.bg-pink-700 ::selection {
  /* To avoid the selection and the background being the same */
  /* bg-ping-200: */
  background-color: #fecdd3;
  color: #000;
}

/* Undo the style resets in .unreset */

.gpt-response.unreset h1 {
  font-size: 1.1em;
}

/* Markdown related styles (undoing the reset): */
/* This keeps the top-level <p> in Markdown from adding a top/bottom margin */
.unreset > div > p:first-child {
  margin-top: 0;
}

.unreset > div > h1,
.unreset > div > h2,
.unreset > div > h3,
.unreset > div > h4,
.unreset > div > h5,
.unreset > div > h6 {
  margin-top: 0;
}

.unreset > div > p:last-child {
  margin-bottom: 0;
}

.unreset pre {
  white-space: pre-wrap;
}

.highlight-bold.unreset strong {
  /* blue-900 */
  color: #1e3a8a;
}

.unreset {
  /**
 * Unreset CSS
 *
 * Copyright (C) 2016 Kite (ixkaito@gmail.com)
 * License MIT
 * Version 1.0.0
 *
 * Unreset CSS restores browsers' default element styles which are reset by
 * Eric Meyer's Reset CSS, YUI 3 Reset CSS, HTML5 Reset Stylesheet of HTML5
 * Doctor, or Tim Murtaugh's HTML5 Reset.
 */

  html {
    box-sizing: content-box;
  }

  body {
    margin: 8px;
    line-height: normal;
  }

  p {
    margin: 1em 0;
  }

  blockquote,
  figure {
    margin: 1em 40px;
  }

  q {
    quotes: "“" "”" "‘" "’";

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }
  }

  hr {
    border: 1px inset;
    box-sizing: border-box;
    margin: 0.5em auto;
  }

  h1 {
    font-size: 2em;
    font-weight: bold;
    margin: 0.67em 0;
  }

  h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.83em 0;
  }

  h3 {
    font-size: 1.17em;
    font-weight: bold;
    margin: 1em 0;
  }

  h4 {
    font-size: 1em;
    font-weight: bold;
    margin: 1.33em 0;
  }

  h5 {
    font-size: 0.83em;
    font-weight: bold;
    margin: 1.67em 0;
  }

  h6 {
    font-size: 0.67em;
    font-weight: bold;
    margin: 2.33em 0;
  }

  article,
  aside,
  nav,
  section {
    h1 {
      font-size: 1.5em;
      font-weight: bold;
      margin: 0.83em 0;
    }

    article,
    aside,
    nav,
    section {
      h1 {
        font-size: 1.17em;
        font-weight: bold;
        margin: 1em 0;
      }

      article,
      aside,
      nav,
      section {
        h1 {
          font-size: 1em;
          font-weight: bold;
          margin: 1.33em 0;
        }

        article,
        aside,
        nav,
        section {
          h1 {
            font-size: 0.83em;
            font-weight: bold;
            margin: 1.67em 0;
          }

          article,
          aside,
          nav,
          section {
            h1 {
              font-size: 0.67em;
              font-weight: bold;
              margin: 2.33em 0;
            }
          }
        }
      }
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
  }

  thead,
  tbody,
  tfoot,
  tr {
    border-color: inherit;
    vertical-align: middle;
  }

  td,
  th {
    padding: 1px;
    vertical-align: inherit;
  }

  th {
    font-weight: bold;
  }

  caption {
    text-align: center;
  }

  ul,
  menu {
    list-style-type: disc;
    margin: 1em 0;
    padding: 0 0 0 40px;
  }

  ol {
    list-style-type: decimal;
    margin: 1em 0;
    padding: 0 0 0 40px;
  }

  ul,
  ol {
    ul {
      list-style-type: circle;
    }

    ul,
    ol {
      ul {
        list-style-type: square;
      }
    }
  }

  dd {
    margin: 0 0 0 40px;
  }

  dl {
    margin: 1em 0;
  }

  ul,
  ol,
  menu,
  dl {
    ul,
    ol,
    menu,
    dl {
      margin: 0;
    }
  }

  legend {
    padding: 0 2px;
  }

  fieldset {
    border-style: groove;
    border-width: 2px;
    border: 2px groove ThreeDFace;
    margin: 0 2px;
    padding: 0 2px 3px;
    -webkit-padding-before: 0.35em;
    -webkit-padding-start: 0.75em;
    -webkit-padding-end: 0.75em;
    -webkit-padding-after: 0.625em;
  }

  ins {
    background-color: transparent;
    font-weight: inherit;
    text-decoration: underline;
  }

  b,
  strong {
    font-weight: bold;
  }

  i,
  cite,
  em,
  var,
  address,
  dfn {
    font-style: italic;
    font-weight: inherit;
  }

  abbr[title],
  dfn[title] {
    border-bottom: 0;
    cursor: default;
    font-weight: inherit;
  }

  tt,
  code,
  kbd,
  samp {
    font-family: monospace;
    font-weight: inherit;
  }

  pre {
    font-family: monospace;
    margin: 1em 0;
    /* white-space: pre; */
  }

  mark {
    background-color: yellow;
    color: black;
    font-style: normal;
    font-weight: inherit;
  }

  big {
    font-size: larger;
    font-weight: inherit;
  }

  small {
    font-size: smaller;
    font-weight: inherit;
  }

  sub,
  sup {
    font-weight: inherit;
    line-height: inherit;
    position: static;
  }

  sub {
    font-size: smaller;
    bottom: 0;
    vertical-align: sub;
  }

  sup {
    font-size: smaller;
    top: 0;
    vertical-align: super;
  }

  ruby {
    > rt {
      font-size: 50%;
    }
  }

  iframe {
    border: 2px inset;
  }

  a {
    color: #3b82f6;
    text-decoration: underline;
  }

  a:hover {
    color: #1d4ed8;
  }
}

.unreset.small-headers {
  h1 {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0.1em 0;
  }

  h2 {
    font-size: 1.1em;
    font-weight: bold;
  }

  h3 {
    font-size: 1em;
    font-weight: bold;
  }

  h4 {
    font-size: 0.83em;
    font-weight: bold;
  }

  h5 {
    font-size: 0.67em;
    font-weight: bold;
  }

  h6 {
    font-size: 0.5em;
    font-weight: bold;
  }
}
